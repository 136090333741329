export class SoraletLog {
  // @ts-expect-error (legacy code incremental fix)
  operatorId: string;
  // @ts-expect-error (legacy code incremental fix)
  soraletId: string;
  // @ts-expect-error (legacy code incremental fix)
  version: number;
  // @ts-expect-error (legacy code incremental fix)
  createdTime: number;
  // @ts-expect-error (legacy code incremental fix)
  message: string;
  // @ts-expect-error (legacy code incremental fix)
  id: string; //FIXME - used to extend Resource for ui-generic-table, need to actually initialize this to some meaningful value

  constructor(params?: any) {
    if (params) {
      this.operatorId = params.operatorId;
      this.soraletId = params.soraletId;
      this.version = params.version;
      this.createdTime = params.createdTime;
      this.message = params.message;
    }
  }
}
