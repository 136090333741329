import { LegacyAny } from '@soracom/shared/core';

import { PaginationOptions, ScRelation } from '../components/paginator';
import {
  LegacyBaseSoracomApiService,
  PaginatableService,
  PaginationService,
  SearchQuery,
  SoracomApiParams,
} from '@user-console/legacy-soracom-api-client';
import { InjectList } from '../core/injectable';
import { SoraletLog } from '../core/soralet_log';

export interface CreateSoraletRequestParams {
  soraletId: string;
  description: string;
}

export interface UpdateSoraletRequestParams {
  description: string;
}

export class SoraletLogsService implements PaginatableService<SoraletLog> {
  static $inject: InjectList = ['$log', '$q', 'BaseSoracomApiService', 'PaginationService'];

  resourcePath = 'soraletLogs';
  // @ts-expect-error (legacy code incremental fix)
  soraletId: string;

  constructor(
    private $log: ng.ILogService,
    private $q: ng.IQService,
    private soracomApi: LegacyBaseSoracomApiService,
    private paginationService: PaginationService,
  ) {}

  list(paginationOptions: PaginationOptions = {}, searchQuery?: SearchQuery): Promise<ScRelation<SoraletLog>> {
    const apiParams: SoracomApiParams = {
      method: 'GET',
      path: `/v1/soralets/${this.soraletId}/logs`,
      query: { ...paginationOptions },
      contentType: 'application/json',
    };

    return this.soracomApi.callApiWithToken(apiParams).then((res: LegacyAny) => {
      const data = res.data.map((d: LegacyAny) => new SoraletLog(d));
      const links = this.paginationService.getPaginationLinks(res.headers.link);
      return { data, links };
    });
  }
}
